export default [
    {
        title: "Rainbow Tail",
        image_loc: "RainbowTail.png",
        rarity: "0.20%"
    },
    {
        title: "Huge Rainbow Tail",
        image_loc: "RainbowLargeTail.png",
        rarity: "0.10%"
    },
    {
        title: "Flame Tail",
        image_loc: "Flame.png",
        rarity: "0.20%"
    },
    {
        title: "Huge Flame Tail",
        image_loc: "FlameLarge.png",
        rarity: "0.10%"
    },


]