export default [
    {
        title: "Alien",
        image_loc: "Alien.png",
        rarity: "0.24%"
    },
    {
        title: "Robot",
        image_loc: "Robot.png",
        rarity: "0.56%"
    },
    {
        title: "Zombie",
        image_loc: "Zombie.png",
        rarity: "1.28%"
    },
    {
        title: "Llama",
        image_loc: "attrplaceholder.png",
        rarity: "97.92%"
    },


]