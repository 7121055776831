import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useRef } from 'react';
import { Link } from 'react-router-dom'
import LlamaProgressBar from "../llama_progress/LlamaProgress"

import "./cta.css";



function CTA() {

    const myRef = useRef<HTMLInputElement>(null)
    const executeScroll = () => myRef?.current?.scrollIntoView()

    return (
        <div className="cta-container">
            <Container>
                <Row className="padded-row">
                    <Col md={{ span: 6, offset: 3 }} >
                        <Image src='/blankmint.png' fluid />

                        <a href="https://solanart.io/collections/sollamas-gen2"><Button >Buy a Llama</Button></a>

                    </Col>
                </Row>

            </Container>
        </div>
    );
}

export default CTA;