export default [
    {
        title: "Desert Flower",
        image_loc: "Cactus.png",
        rarity: "10.91%"
    },
    {
        title: "Desert Cactus",
        image_loc: "CactusLarge.png",
        rarity: "11.03%"
    },
    {
        title: "Farm Fence",
        image_loc: "Fence.png",
        rarity: "11.70%"
    },
    {
        title: "Barn Fence",
        image_loc: "FenceDouble.png",
        rarity: "11.05%"
    },

    {
        title: "Flower Meadow",
        image_loc: "Grass.png",
        rarity: "10.61%"
    },
    {
        title: "Mushroom Meadow",
        image_loc: "GrassMushroom.png",
        rarity: "11.05%"
    },
    {
        title: "Snowy Mountain",
        image_loc: "Mountain.png",
        rarity: "11.02%"
    },
    {
        title: "Butte Mountain",
        image_loc: "MountainDesert.png",
        rarity: "11.04%"
    },
    {
        title: "Rainbow Love",
        image_loc: "RainbowLove.png",
        rarity: "0.51%"
    },
    {
        title: "Shooting Star",
        image_loc: "Star.png",
        rarity: "0.49%"
    },
    {
        title: "Morning Sun",
        image_loc: "Sun.png",
        rarity: "10.59%"
    },

]