import React, { useState, useContext } from "react";
import {
  wallet,
  initWallet,
  setAllMints,
  transferTuco,
} from "../../solana/wallet";
import { API_URL } from "../../config";
import axios from "axios";

import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "react-bootstrap/Button";
import WalletButton from "../wallet/Wallet";
import { Link, NavLink } from "react-router-dom";
import {
  LlamaWalletContext,
  ILlamaWallet,
  walletType,
  ILlamaNFT,
} from "../wallet/WalletContext";
import {
  LoadStatusContextProvider,
  LoadStatusContext,
  Status,
} from "../wallet/LoadingContext";
import "./MySollamas.css";
import { mintNewEditionFromMasterEditionViaToken } from "../../solana/metadata";

const REVIVAL_URL =
  "https://o4ja5amgo1.execute-api.us-east-1.amazonaws.com/prod/tuco";

interface Attribute {
  trait_type: string;
  value: string;
}

interface Meta {
  mint_number: number;
  file_name: string;
  attributes: Attribute[];
}

function MySollamas() {
  const { llamaWallet, setLlamaWallet } = useContext(LlamaWalletContext);
  const { loadStatus, setLoadStatus } = useContext(LoadStatusContext);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setModalState(1);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [modalMint, setModalMint] = useState<ILlamaNFT>();
  const [modalState, setModalState] = useState(1);

  async function revive() {
    // modalMint is the selected revival grave
    console.log(modalMint);

    const grave = modalMint!.mintId;
    const t = llamaWallet!.tucosOwned.pop()!;
    const tuco = t.mintId;
    const tucotoken = t.tokenAddr;
    const wallet = llamaWallet!.walletID;
    const txid = await transferTuco(tuco, tucotoken);

    const payload = { tuco, grave, wallet, txid, tucotoken };

    setLlamaWallet(llamaWallet);
    console.log(payload);
    await axios.post(REVIVAL_URL, payload);
    console.log("revival request sent");
    setModalState(4);
  }

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  async function fakeRevive() {
    await sleep(10000);
    setModalState(4);
  }

  function CrackedGraveCard(mint: ILlamaNFT) {
    return (
      <Col className="my-sollamas-card" md={{ span: 4 }}>
        <Image src={mint.awImage} fluid />
        <span>Cracked Grave</span>
        <h4>Spooky</h4>

        <hr></hr>
      </Col>
    );
  }

  function UndeadCard(mint: ILlamaNFT) {
    return (
      <Col className="my-sollamas-card" md={{ span: 4 }}>
        <Link to={"/sollama/" + mint.hash + "emacocut"}>
          <Image src={mint.awImage} fluid />
        </Link>

        <span>{mint.name}</span>
        <h4>Mint #{mint.mintNum}</h4>
        <hr></hr>
      </Col>
    );
  }

  function GraveCard(mint: ILlamaNFT) {
    return (
      <Col className="my-sollamas-card" md={{ span: 4 }}>
        <Image src={mint.awImage} fluid />
        <span>{mint.name.split(",")[0] + ", the DED"}</span>
        <h4>Ded #{mint.mintNum}</h4>

        {llamaWallet?.tucosOwned && llamaWallet?.tucosOwned.length > 0 ? (
          <Button
            onClick={() => {
              setModalMint(mint);
              handleShow();
            }}
          >
            ⚡ Resurrect ⚡
          </Button>
        ) : (
          <></>
        )}

        <hr></hr>
      </Col>
    );
  }

  function LlamaCard(mint: ILlamaNFT) {
    return (
      <Col className="my-sollamas-card" md={{ span: 4 }}>
        <Link to={"/sollama/" + mint.hash}>
          <Image src={mint.awImage} fluid />
        </Link>
        <span>{mint.name}</span>
        <h4>Mint #{mint.mintNum}</h4>
        <hr></hr>
      </Col>
    );
  }

  function TucoCard(mint: ILlamaNFT) {
    return (
      <Col className="my-sollamas-card" md={{ span: 4 }}>
        <Link to={"/sollama/" + mint.hash}>
          <Image src={mint.awImage} fluid />
        </Link>
        <span>{mint.name.split(",")[0] + ", the DED"}</span>
        <h4>Mint # 💀</h4>
        <hr></hr>
      </Col>
    );
  }

  if (loadStatus == Status.NotConnected) {
    return (
      <>
        <div className="my-sollamas">
          <Row className="hero">
            <Container>
              <Row>
                <Col>
                  <h1 className="text-left">My Sollamas</h1>
                  <p className="text-left">View your minted Sollamas</p>
                </Col>
              </Row>
            </Container>
          </Row>
          <Container>
            <Row className="attr-row padded-row text-left">
              <Col>
                <h2>How to view your Sollamas</h2>
                <p>To view your Sollamas, connect your Solana wallet.</p>
              </Col>
            </Row>
            <Row className="padded-row-bottom">
              <Col md={3}>
                {/* <Button onClick={() => { setWallet(wallet1); connectWallet(true) }} variant="outline-info btn-block">Connect Wallet</Button> */}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  } else if (loadStatus == Status.LoadingArweave) {
    return (
      <>
        <div className="my-sollamas">
          <Row className="hero">
            <Container>
              <Row>
                <Col>
                  <h1 className="text-left">My Sollamas</h1>
                  <p className="text-left">View your minted Sollamas</p>
                </Col>
              </Row>
            </Container>
          </Row>
          <Container>
            <Spinner animation="border" />
          </Container>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="my-sollamas">
          <Modal
            className="revive-modal"
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            {modalState === 1 ? (
              <>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <p>
                      You are about to resurrect{" "}
                      <strong>{modalMint?.name}</strong>
                    </p>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                  <p>🔥 A Tuco will leave your wallet and be burnt 🔥</p>
                  <p>The gravestone can never be revived again</p>
                  <p>Soon, a fresh undead will arrive in your wallet.</p>
                </Modal.Body>
                <Button onClick={() => setModalState(2)}>
                  Review Resurrection
                </Button>
              </>
            ) : modalState === 2 ? (
              <>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Are you really, really sure?</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                  <p></p>
                  <Image
                    className="modal-llama text-center"
                    src={"grave.png"}
                  ></Image>{" "}
                  <span>➡️ </span>
                  <Image
                    className="modal-llama text-center"
                    src={"grave_cracked.png"}
                  ></Image>
                  <br></br>
                  <br></br>
                  <Image
                    className="modal-llama text-center"
                    src={"tuco.png"}
                  ></Image>{" "}
                  <span>➡️ </span>
                  <Image
                    className="modal-llama text-center"
                    src={"/undead/nfts_num/" + modalMint?.mintNum + ".png"}
                  ></Image>
                  <p></p>
                  <p>This process is perminent and cannot ever be reversed.</p>
                </Modal.Body>
                <Button
                  onClick={() => {
                    revive();
                    setModalState(3);
                  }}
                >
                  Yes, fucking resurrect it!
                </Button>
              </>
            ) : modalState === 3 ? (
              <>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>Resurrecting...</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <p>Resurrecting your llama ... this may take a few minutes</p>
                </Modal.Body>
              </>
            ) : modalState === 4 ? (
              <>
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h4>🎉 Congratulations</h4>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Your resurrected {modalMint?.name} is in the mail.</p>
                </Modal.Body>
              </>
            ) : (
              <>Error</>
            )}
          </Modal>

          <Row className="hero">
            <Container>
              <Row>
                <Col>
                  <h1 className="text-left">My Sollamas</h1>
                  <p className="text-left">View your minted Sollamas</p>
                </Col>
              </Row>
            </Container>
          </Row>
          <Container>
            {llamaWallet?.undeadsOwned ? (
              <Row className="padded-row">
                {llamaWallet?.undeadsOwned.map((sollama) => {
                  return UndeadCard(sollama);
                })}
              </Row>
            ) : (
              <></>
            )}

            {llamaWallet?.gravesOwned ? (
              <Row className="">
                {llamaWallet?.gravesOwned.map((sollama) => {
                  return GraveCard(sollama);
                })}
              </Row>
            ) : (
              <></>
            )}
            {llamaWallet?.llamasOwned ? (
              <Row className="">
                {llamaWallet?.llamasOwned.map((sollama) => {
                  return LlamaCard(sollama);
                })}
              </Row>
            ) : (
              <></>
            )}
            {llamaWallet?.tucosOwned ? (
              <Row className="">
                {llamaWallet?.tucosOwned.map((sollama) => {
                  return TucoCard(sollama);
                })}
              </Row>
            ) : (
              <></>
            )}
            {llamaWallet?.crackedGravesOwned ? (
              <Row className="">
                {llamaWallet?.crackedGravesOwned.map((sollama) => {
                  return CrackedGraveCard(sollama);
                })}
              </Row>
            ) : (
              <></>
            )}
          </Container>
        </div>
      </>
    );
  }
}

export default MySollamas;
