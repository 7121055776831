import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { Link } from "react-router-dom";
import { API_URL } from "../../config";

import "./Showcase.css";

interface IAttrRef {
  name: string;
  clean_name: string;
}

interface ILlamaRef {
  file_name: string;
  mint_number: number;
  gender: string;
  n_accessories: number;
  bg_type: IAttrRef;
  body_colour: string;
  llama_type: string;
  pattern: IAttrRef;
  rarity: number;
  first_name: string;
  moniker: string;
  description: string;
  rank: number;
  tail: IAttrRef;
  attributes: IAttrRef[];
}

const blankAttr: IAttrRef = {
  name: "",
  clean_name: "",
};

const blankLlama: ILlamaRef = {
  file_name: "",
  mint_number: 0,
  gender: "",
  n_accessories: 0,
  bg_type: blankAttr,
  body_colour: "string",
  llama_type: "",
  pattern: blankAttr,
  rarity: 0,
  first_name: "",
  moniker: "",
  description: "",
  rank: 0,
  tail: blankAttr,
  attributes: [blankAttr],
};

function Showcase() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  let { id } = useParams<{ id: string }>();
  const [setName, setSetName] = useState("base");
  const [cleanId, setCleanId] = useState("");

  const [showcase_llama, setShowcaseLlama] = useState(blankLlama);

  useEffect(() => {
    if (id.includes("emacocut")) {
      setSetName("undead");
    }
    let cleanedID = id.split("emacocut")[0];
    setCleanId(cleanedID);
    fetch(`${API_URL}/meta?hash=${cleanedID}`)
      .then((res) => res.json())
      .then((data: ILlamaRef) => {
        var llama = data;

        var dupeAttrs = llama.attributes;
        var cleanAttrs = Array();
        var usedNames = Array();
        if (dupeAttrs !== undefined) {
          dupeAttrs.forEach((a) => {
            if (!usedNames.includes(a.clean_name)) {
              if (!a.clean_name.includes("Tail")) {
                usedNames.push(a.clean_name);
                cleanAttrs.push(a);
              } else {
                let tail: IAttrRef = {
                  name: a.name,
                  clean_name: a.clean_name,
                };
                llama.tail = tail;
              }
            }
          });
        }
        if (["Fawn", "Brown", "Grey", "Orange"].includes(llama.llama_type)) {
          llama.llama_type = "Llama";
        }

        llama.attributes = cleanAttrs;
        setShowcaseLlama(llama);
      });
  }, []);

  return (
    <div className="showcase">
      <Modal
        className="showcase-modal"
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>
              <Image src="/download_white.svg"></Image>Download your Assets
            </h3>
            <p>Choose the file to download</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {setName == "undead" ? (
            <Link
              to={"/undead/nft/" + cleanId + ".png"}
              target="_blank"
              download
            >
              <Button
                className="modal-wallet-btn text-left"
                block
                variant="primary"
              >
                <Image src={"/undead/nft/" + cleanId + ".png"} />
                NFT Art
                <Image
                  className="asset-dl-icon float-right"
                  src="/download_white.svg"
                />
              </Button>
            </Link>
          ) : (
            <Link to={"/llamas/nft/" + id + ".png"} target="_blank" download>
              <Button
                className="modal-wallet-btn text-left"
                block
                variant="primary"
              >
                <Image src={"/llamas/nft/" + id + ".png"} />
                NFT Art
                <Image
                  className="asset-dl-icon float-right"
                  src="/download_white.svg"
                />
              </Button>
            </Link>
          )}
          {setName == "undead" ? (
            <Link
              to={"/undead/cards_squared/" + cleanId + "_card.png"}
              target="_blank"
              download
            >
              <Button
                className="modal-wallet-btn text-left"
                block
                variant="primary"
                onClick={() => {}}
              >
                <Image src={"/undead/cards_squared/" + cleanId + "_card.png"} />
                Collectible Card
                <Image
                  className="asset-dl-icon float-right"
                  src="/download_white.svg"
                />
              </Button>
            </Link>
          ) : (
            <Link
              to={"/llamas/cards_squared/" + id + "_card.png"}
              target="_blank"
              download
            >
              <Button
                className="modal-wallet-btn text-left"
                block
                variant="primary"
                onClick={() => {}}
              >
                <Image src={"/llamas/cards_squared/" + id + "_card.png"} />
                Collectible Card
                <Image
                  className="asset-dl-icon float-right"
                  src="/download_white.svg"
                />
              </Button>
            </Link>
          )}
          {setName == "undead" ? (
            <Link
              to={"/undead/pfps/" + cleanId + "_avatar.png"}
              target="_blank"
              download
            >
              <Button
                className="modal-wallet-btn text-left"
                block
                variant="primary"
                onClick={() => {}}
              >
                <Image src={"/undead/pfps/" + cleanId + "_avatar.png"} />
                Profile Picture
                <Image
                  className="asset-dl-icon float-right"
                  src="/download_white.svg"
                />
              </Button>
            </Link>
          ) : (
            <Link
              to={"/llamas/pfps/" + id + "_avatar.png"}
              target="_blank"
              download
            >
              <Button
                className="modal-wallet-btn text-left"
                block
                variant="primary"
                onClick={() => {}}
              >
                <Image src={"/llamas/pfps/" + id + "_avatar.png"} />
                Profile Picture
                <Image
                  className="asset-dl-icon float-right"
                  src="/download_white.svg"
                />
              </Button>
            </Link>
          )}
        </Modal.Body>
      </Modal>

      <div className="showcase-hero">
        <Container>
          <Row>
            <Col md={3} className="text-left">
              <Link to="/my-sollamas">My Sollamas</Link> / #
              {showcase_llama.mint_number}
            </Col>
            <Col md={6}>
              {setName == "undead" ? (
                <Image
                  className="showcase-card"
                  src={
                    "/undead/cards_squared/" +
                    showcase_llama.file_name +
                    "_card.png"
                  }
                  fluid
                />
              ) : (
                <Image
                  className="showcase-card"
                  src={
                    "/llamas/cards_squared/" +
                    showcase_llama.file_name +
                    "_card.png"
                  }
                  fluid
                />
              )}
            </Col>
            <Col md={3} className="text-right">
              <Button
                onClick={() => {
                  setShow(true);
                }}
                className="btn-secondary dl-button"
              >
                <Image src="/download.svg" />
                Download
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="showcase-attrs">
        <Row className="padded-row description-row">
          <Col className="text-left">
            <h1>
              {showcase_llama.first_name}, the {showcase_llama.moniker}
              {setName == "undead" ? <> (Spooky Edition)</> : <></>}
            </h1>

            {setName == "undead" ? <></> : <p>{showcase_llama.description}</p>}
          </Col>
        </Row>
        <Row className="text-left attr-kind-row">
          <Col>
            <h3>Breed</h3>
            <hr></hr>
            <Row>
              <Col className="col-lg-6 col-md-12 col-12 no-pad">
                <Row>
                  <Col className="col-md-4">
                    <Image
                      src={
                        "/attributes/colour/" +
                        showcase_llama.llama_type +
                        ".png"
                      }
                      fluid
                    />
                  </Col>
                  <Col className="col-md-6 attr-text align-self-center">
                    <h4>{showcase_llama.llama_type}</h4>
                    {/* <p>One of <strong>??</strong> {showcase_llama.body_colour} Llamas </p> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="text-left attr-kind-row">
          <Col>
            <h3>Pattern</h3>
            <hr></hr>
            <Row>
              <Col className="col-lg-6 col-md-12 col-12 no-pad">
                <Row>
                  <Col className="col-md-4">
                    <Image
                      src={
                        "/attributes/colour/" +
                        showcase_llama.pattern.name +
                        ".png"
                      }
                      fluid
                    />
                  </Col>
                  <Col className="col-md-6 attr-text align-self-center">
                    <h4>{showcase_llama.pattern.clean_name}</h4>
                    {/* <p>One of <strong>??</strong> {showcase_llama.body_colour} Llamas </p> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="text-left attr-kind-row">
          <Col>
            <h3>Traits</h3>
            <hr></hr>
            <Row>
              {showcase_llama.attributes.map((attr) => {
                return (
                  <Col className="col-lg-6 col-md-6 col-12 no-pad">
                    <Row>
                      <Col className="col-md-4">
                        <Image
                          src={"/attributes/colour/" + attr.name + ".png"}
                          fluid
                        />
                      </Col>
                      <Col className="col-md-6 attr-text align-self-center">
                        <h4>{attr.clean_name}</h4>
                        {/* <p><strong>??</strong> Sollamas have this trait </p> */}
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        {showcase_llama?.tail ? (
          showcase_llama?.tail?.clean_name != "" ? (
            <Row className="text-left attr-kind-row">
              <Col>
                <h3>Tail</h3>
                <hr></hr>
                <Row>
                  <Col className="col-lg-6 col-md-12 col-12 no-pad">
                    <Row>
                      <Col className="col-md-4">
                        <Image
                          src={
                            "/attributes/colour/" +
                            showcase_llama.tail?.name +
                            ".png"
                          }
                          fluid
                        />
                      </Col>
                      <Col className="col-md-6 attr-text align-self-center">
                        <h4>{showcase_llama.tail?.clean_name}</h4>
                        {/* <p>One of <strong>??</strong> {showcase_llama.tail?.clean_name} tails</p> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        <Row className="text-left attr-kind-row">
          <Col>
            <h3>Backdrop</h3>
            <hr></hr>
            <Row>
              <Col className="col-lg-6 col-md-12 col-12 no-pad">
                <Row>
                  <Col className="col-md-4">
                    <Image
                      src={
                        "/attributes/colour/" +
                        showcase_llama.bg_type.name +
                        ".png"
                      }
                      fluid
                    />
                  </Col>
                  <Col className="col-md-6 attr-text align-self-center">
                    <h4>{showcase_llama.bg_type.clean_name}</h4>
                    {/* <p>One of <strong>??</strong> {showcase_llama.bg_type.clean_name} backdrops</p> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="text-left attr-kind-row">
          <Col>
            <h3>Trait Count</h3>
            <hr></hr>
            <Row>
              <Col className="col-lg-12 col-md-12 col-12 no-pad">
                <Row>
                  <Col className="col-md-6">
                    <Image
                      src={
                        "/hist_" +
                        Math.round(showcase_llama.n_accessories) +
                        ".svg"
                      }
                      fluid
                    />
                  </Col>
                  <Col className="col-md-6 attr-text align-self-center">
                    <h4>{Math.round(showcase_llama.n_accessories)} Traits</h4>
                    {/* <p><strong>??</strong> Sollamas have this many traits</p> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Showcase;
