import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import "./roadmap.css";



function Roadmap() {

    return (
        <div className='roadmap'>
            <div className="roadmap-header">
                <Container className="roadmap-header">
                    <Row className='text-center'>
                        <Col>
                            <h1>Sollamas NFT Roadmap</h1>
                            <p>
                                Sollamas is more than just an awesome generative NFT project
                            </p>
                        </Col>

                    </Row>
                </Container>
            </div>
            <Container>
                <Row className='text-center'>
                    <Col>
                        <section className="timeline">
                            <ul>
                                <li className="delivered">
                                    <div className="text-left tl-card">
                                        <div className="tl-title"><span>01</span><span> Gen 1</span></div>
                                        <div className="tl-subtitle"><h4>First generation of Sollamas NFT</h4></div>
                                        <p>
                                            Launch all 10,000 algorithmically generated Sollamas to our awesome community.
                                        </p>
                                    </div>
                                </li>
                                <li className="delivered">
                                    <div className="text-left tl-card">
                                        <div className="tl-title"><span>02</span><span> Gen 1</span></div>
                                        <div className="tl-subtitle"><h4>Llamassacre</h4></div>
                                        <p>
                                            Tucos here and hes out for blood.
                                            The Llamas you knew are ded. All ded. Nothing but gravestones remain.
                                        </p>
                                    </div>
                                </li>
                                <li className="delivered">
                                    <div className="text-left tl-card">
                                        <div className="tl-title"><span>03</span><span> Gen 2</span></div>
                                        <div className="tl-subtitle"><h4>Llamas are back</h4></div>
                                        <p>
                                            New Llamas have moved back into town. Blissfully unaware of Tuco and his madness.
                                        </p>
                                    </div>
                                </li>
                                <li className="delivered">
                                    <div className="text-left tl-card">
                                        <div className="tl-title"><span>04</span><span> </span></div>
                                        <div className="tl-subtitle"><h4>Marketplace integration</h4></div>
                                        <p>
                                            Buy, sell and trade Sollamas on a solanart.io. This will be released soon after launch!
                                        </p>
                                    </div>
                                </li>

                                <li className="delivered">
                                    <div className="text-left tl-card">
                                        <div className="tl-title"><span>05</span><span> </span></div>
                                        <div className="tl-subtitle"><h4>Exclusive Airdrop for all Gen2 holders</h4></div>
                                        <p>
                                            Holding a Gen2 Sollama NFT puts you in the running to receive an extremely rare and exclusive Solana edition NFT! The more you hold - the more chances of receiving one.

                                        </p>
                                    </div>
                                </li>
                                <li className="">
                                    <div className="text-left tl-card">
                                        <div className="tl-title"><span>06</span><span> </span></div>
                                        <div className="tl-subtitle"><h4>Tuco and the Gravestones</h4></div>
                                        <p>
                                            Gravestones remain... for now. Tuco has a trick up his sleeve.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="text-left tl-card">
                                        <div className="tl-title"><span>07</span><span> </span></div>
                                        <div className="tl-subtitle"><h4>Sollamas NFT merch</h4></div>
                                        <p>
                                            Who doesn't want a hoodie, tee, cap or sticker set with an ultra rare alien or robot?
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div className="text-left tl-card">
                                        <div className="tl-title"><span>08</span><span> </span></div>
                                        <div className="tl-subtitle"><h4>Next generation of Sollamas NFT</h4></div>
                                        <p>
                                            We’re going to keep this one a secret while we are hard at work building out the system but here’s a hint… Llamas are cutest when they’re babies. Expect some news!
                                        </p>
                                    </div>
                                </li>

                            </ul>
                        </section>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Roadmap;