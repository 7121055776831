import React, { useState, useContext, useEffect } from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import ConnectBtn from "../connect_btn/ConnectBtn";
import Image from 'react-bootstrap/Image';
import { LlamaWalletContext, ILlamaWallet, walletType, } from "../wallet/WalletContext"

import "./Nav.css";
import { Link, NavLink } from "react-router-dom";

function Navigation() {

  const { llamaWallet, setLlamaWallet } = useContext(LlamaWalletContext);

  var nLlamas = 0

  useEffect(() => {
    var nLlamas = llamaWallet?.llamasOwned?.length

  });
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar expanded={expanded} collapseOnSelect expand="lg" className="nav-bg sticky-top">
      <Container fluid>
        <Navbar.Brand href="/" className="brand ml-auto"><Image src='/sollamaslogowhite.svg' fluid /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='custom-toggler mr-auto' onClick={() => setExpanded(true)} />
        <Navbar.Collapse id="responsive-navbar-nav ">
          <Nav className="mx-auto">
            <NavLink onClick={() => setExpanded(false)} activeClassName='is-active' className="nav-link" exact={true} to="/">Home</NavLink>
            <NavLink onClick={() => setExpanded(false)} activeClassName='is-active' className="nav-link" to="/attributes">Attributes</NavLink>
            <NavLink onClick={() => setExpanded(false)} activeClassName='is-active' className="nav-link" to="/my-sollamas">My Sollamas</NavLink>
            <NavLink onClick={() => setExpanded(false)} activeClassName='is-active' className="nav-link" to="/roadmap">Roadmap</NavLink>
            <NavLink onClick={() => setExpanded(false)} activeClassName='is-active' className="nav-link" to="/faq">FAQ</NavLink>
          </Nav>
        </Navbar.Collapse>
        <div className="connect-container">
          <Navbar.Collapse id="responsive-navbar-nav " className="mr-auto" onClick={() => setExpanded(false)}>
            <a href="https://solanart.io/collections/sollamas-gen2"><Button className="buy-now" >Buy a Llama</Button></a>
            <ConnectBtn />

          </Navbar.Collapse>
        </div>
      </Container>
    </Navbar >


  )
}

export default Navigation;