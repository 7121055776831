const ENV: string = "prod";

let api = "https://api.sollamas.com";
let solanaCluster = "https://sollamas.rpcpool.com/";

// if (ENV === "prod" || ENV === "softlaunch") {
//   api = "https://api.sollamas.com";
//   solanaCluster = "https://sollamas.rpcpool.com/";
// } else {
//   api = "https://api-test.sollamas.com";
//   solanaCluster = "https://sollamas.devnet.rpcpool.com/";
// }

export const API_URL = api;
export const CLUSTER_URL = solanaCluster;

