import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import "./Footer.css";
import Image from "react-bootstrap/Image";
import { NavLink } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <Container>
        <Row className="padded-row text-left">
          <Col className="col-md-6 col-12">
            <Navbar.Brand href="/" className="brand">
              <Image src="/sollamaslogodark.svg" fluid />
            </Navbar.Brand>
            <p className="bio">
              8,888 algorithmically generated, cute and collectible Llamas with
              proof of ownership stored on the Solana blockchain.
            </p>
            <Row className="socials">
              <Col>
                <a href="https://twitter.com/SollamasNFT">
                  <Image src="/twitter.svg"></Image>
                </a>
                <a href="https://discord.gg/hnMThMeCaa">
                  <Image src="/discord.svg"></Image>
                </a>
                <a href="https://twitter.com/SollamasNFT">
                  <Image src="/joinus.svg"></Image>
                </a>
              </Col>
            </Row>
          </Col>
          <Col className="footer-links-menu col-md-6 col-12">
            <Container>
              <Row>
                <Col>
                  <p className="footer-header">SOLLAMAS</p>

                  <NavLink
                    activeClassName="footer-is-active"
                    className="footer-nav-link"
                    exact={true}
                    to="/"
                  >
                    Home
                  </NavLink>
                  <NavLink
                    activeClassName="footer-is-active"
                    className="footer-nav-link"
                    to="/attributes"
                  >
                    Attributes
                  </NavLink>
                  <NavLink
                    activeClassName="footer-is-active"
                    className="footer-nav-link"
                    to="/my-sollamas"
                  >
                    My Sollamas
                  </NavLink>
                  <NavLink
                    activeClassName="footer-is-active"
                    className="footer-nav-link"
                    to="/roadmap"
                  >
                    Roadmap
                  </NavLink>
                  <a
                    className="footer-nav-link"
                    href="https://solanart.io/collections/sollamas-gen2"
                  >
                    Buy a Llama
                  </a>
                </Col>
                <Col>
                  <p className="footer-header">SUPPORT</p>
                  <NavLink
                    activeClassName="footer-is-active"
                    className="footer-nav-link"
                    to="/faq"
                  >
                    FAQs
                  </NavLink>
                  {/* <NavLink activeClassName='footer-is-active' className="footer-nav-link" to="contact-us">Contact Us</NavLink> */}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
