import "./App.css";
import { useContext } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Navigation from "./components/nav/Nav";
import Attributes from "./components/attributes/Attributes";
import MySollamas from "./components/mysollamas/MySollamas";
import Faq from "./components/faq/Faq";
import Home from "./components/home/Home";
import Footer from "./components/footer/Footer";
import ScrollToTop from "./components/ScrollToTop";
import CTA from "./components/cta/cta";
import Roadmap from "./components/roadmap/roadmap";
import Showcase from "./components/showcase/Showcase";
import NotFound from "./components/status/NotFound";


import { LlamaWalletContextProvider, LlamaWalletContext } from "./components/wallet/WalletContext"
import { LoadStatusContextProvider, LoadStatusContext } from "./components/wallet/LoadingContext"


function App() {
  document.title = "Sollamas NFT | Rare & Collectible NFT Llamas on Solana"
  const { llamaWallet } = useContext(LlamaWalletContext);
  const { loadStatus } = useContext(LoadStatusContext);


  return (
    < Router >
      <div className="App">
        <LlamaWalletContextProvider>
          <LoadStatusContextProvider>
            <Navigation />
            <ScrollToTop />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/buy/:section" component={Home} />
              <Route path="/attributes" component={Attributes} />
              <Route path="/my-sollamas" component={MySollamas} />
              <Route path="/roadmap" component={Roadmap} />
              <Route path="/faq" component={Faq} />
              <Route path="/sollama/:id" component={Showcase} />
              <Route component={NotFound} />

            </Switch>
            <CTA />
            <Footer />
          </LoadStatusContextProvider>
        </LlamaWalletContextProvider>

      </div>
    </Router >
  );
}

export default App;
