import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';

import "./Faq.css";

import faq from "./faq_data"



function Faq() {
  return (
    <div className="faqs">
      <Row className="hero">
        <Container>
          <Row>
            <Col>
              <h1 className="text-left">How can we help you?</h1>
              <p className="text-left">Everything you need to know to in the world of Sollamas.</p>
            </Col>

          </Row>
        </Container>
      </Row>
      <Container>
        <Row className="text-left padded-row">
          <Col>
            <Accordion defaultActiveKey="0">
              {
                faq.map(faq => {
                  return (
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey={String(faq.n)}>
                        <h4>{faq.question}</h4>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={String(faq.n)}>
                        <Card.Body>{faq.answer.split('\n').map(line => <p>{line}</p>)}</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )
                })}


            </Accordion>

          </Col>
        </Row>

      </Container>

    </div >
  );
}

export default Faq;