import React from 'react'

export enum Status {
    NotConnected = -1,
    ConnectingWallet = 0,
    LoadingArweave = 1,
    Connected = 999
}


const useValue = () => {
    const [loadStatus, setLoadStatus] = React.useState(Status.NotConnected)

    return {
        loadStatus,
        setLoadStatus,
    }
}

export const LoadStatusContext = React.createContext({} as ReturnType<typeof useValue>)

export const LoadStatusContextProvider: React.FC<{}> = (props) => {
    return (
        <LoadStatusContext.Provider value={useValue()}>
            {props.children}
        </LoadStatusContext.Provider>
    )
}

