import ConnectBtn from "../connect_btn/ConnectBtn";
import ProgressBar from "react-bootstrap/ProgressBar";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect, useRef, useContext } from "react";
import { API_URL } from "../../config";
import { Link } from 'react-router-dom'

import "./LlamaProgress.css";


function LlamaRemaining() {
    const [count, setCount] = useState(0);
    useEffect(() => {
        fetch(`${API_URL}/num-sold`)
            .then((res) => res.json())
            .then((data) => {
                setCount(data.count);
            });
    });
    const myRef = useRef<HTMLInputElement>(null)
    const executeScroll = () => myRef?.current?.scrollIntoView()

    return (
        <div className='llama-progress' >
            {
                count == 10000 ?
                    <>
                        <span className="sold-out">SOLD OUT</span><br></br>

                    </>
                    :
                    <>
                        <p>
                            Only <span className="minted">{10000 - count} </span>of{" "}
                            <span className="to-mint">10,000</span> remaining
                        </p>


                    </>
            }



        </div >
    );
}

export default LlamaRemaining;
