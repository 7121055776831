import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { initWallet, disconnectWallet, setAllMints } from "../../solana/wallet";
import {
  LoadStatusContextProvider,
  LoadStatusContext,
  Status,
} from "../wallet/LoadingContext";
import {
  LlamaWalletContext,
  ILlamaWallet,
  walletType,
} from "../wallet/WalletContext";

import "./ConnectBtn.css";

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function ConnectBtn() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const { loadStatus, setLoadStatus } = useContext(LoadStatusContext);

  const [finishedLoading, setFinishedLoading] = useState(false);
  const { llamaWallet, setLlamaWallet } = useContext(LlamaWalletContext);

  async function ConnectWallet(provider: number) {
    let w = await initWallet(provider);

    var attempts = 0;
    while (!w.connected) {
      await sleep(1000);
      attempts += 1;
    }
    const pk = w!.publicKey!.toString();

    setLoadStatus(Status.LoadingArweave);

    const lw: ILlamaWallet = {
      walletID: pk,
      walletProvider: provider,
      walletProviderName: walletType[provider],
      llamasOwned: [],
      gravesOwned: [],
      crackedGravesOwned: [],
      undeadsOwned: [],
      ghostsOwned: [],
      tucosOwned: [],
    };
    setLlamaWallet(lw);

    await Promise.resolve(await setAllMints(pk)).then((data) => {
      data.forEach((element: any) => {
        if (element.generation === "tuco") {
          lw.tucosOwned.push(element);
        } else if (element.generation === "graves") {
          lw.gravesOwned.push(element);
        } else if (element.generation === "base") {
          lw.llamasOwned.push(element);
        } else if (element.generation === "crackedGraves") {
          lw.crackedGravesOwned.push(element);
        } else if (element.generation === "undead") {
          lw.undeadsOwned.push(element);
        }
      });

      setLoadStatus(Status.Connected);
    });
  }

  function DisconnectWallet() {
    disconnectWallet();
    setLlamaWallet(undefined);
    setLoadStatus(Status.NotConnected);
  }

  return (
    <>
      {loadStatus > Status.ConnectingWallet ? (
        <>
          <Button
            variant="outline-info"
            className="connect-btn"
            onClick={handleShow}
          >
            <Image src="/wallet_connected.svg"></Image>
            <Image src="/wallet.svg"></Image>
            {llamaWallet?.walletID.substring(0, 3)}...
            {llamaWallet?.walletID.substring(llamaWallet?.walletID.length - 3)}
          </Button>
          <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton className="connected-header">
              <Modal.Title>
                <h3>
                  <Image src="/wallet.svg"></Image>Your{" "}
                  {llamaWallet?.walletProviderName} wallet
                </h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="connected-body">
              <Image src="/wallet_connected.svg"></Image>{" "}
              <p>{llamaWallet?.walletID}</p>
              <Button
                className="btn-secondary modal-dc"
                onClick={DisconnectWallet}
              >
                Disconnect
              </Button>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <>
          {loadStatus >= Status.LoadingArweave ? (
            <Button
              variant="outline-info"
              className="connect-btn"
              onClick={handleShow}
            >
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </Button>
          ) : (
            <Button
              variant="outline-info"
              className="connect-btn"
              onClick={handleShow}
            >
              <Image src="/wallet_unconnected.svg"></Image>
              <Image src="/wallet.svg"></Image>Connect
            </Button>
          )}

          <Modal
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h3>
                  <Image src="/wallet.svg"></Image>Connect your wallet
                </h3>
                <p>To get started securely, connect your wallet.</p>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Button
                className="modal-wallet-btn text-left"
                block
                variant="primary"
                onClick={() => {
                  ConnectWallet(walletType.Phantom);
                  setShow(false);
                }}
              >
                <Image src="/phantom.svg" fluid />
                Phantom
              </Button>
              <Button
                className="modal-wallet-btn text-left"
                block
                variant="primary"
                onClick={() => {
                  ConnectWallet(walletType.Sollet);
                  setShow(false);
                }}
              >
                <Image src="/sollet.svg" fluid />
                Sollet
              </Button>
            </Modal.Body>
            <Modal.Footer>
              <h4>Need Help?</h4>
              <p>
                Visit our <a href="faq">FAQs</a> for more info or get in touch
                with us on Discord or Twitter.{" "}
              </p>

              <div className="modal-footer-socials">
                <a href="https://twitter.com/SollamasNFT">
                  <Image src="/twitter.svg"></Image>
                </a>
                <a href="discord.gg/sollamas">
                  <Image src="/discord.svg"></Image>
                </a>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default ConnectBtn;
