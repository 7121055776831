import React from "react";
import internal from "stream";

export enum walletType {
  Phantom = 0,
  Sollet = 1,
}

export interface ILlamaAttr {
  traitType: string;
  value: string;
}

export interface ILlamaNFT {
  mintId: string;
  tokenAddr: string;
  awImage: string;
  mintNum: number;
  generation: string;
  description: string;
  name: string;
  isDed: boolean;
  order: number;
  hash: string;
  attributes: ILlamaAttr[];
}

export interface ILlamaWallet {
  walletID: string;
  walletProvider: number;
  walletProviderName: string;
  llamasOwned: ILlamaNFT[];
  gravesOwned: ILlamaNFT[];
  crackedGravesOwned: ILlamaNFT[];
  undeadsOwned: ILlamaNFT[];
  ghostsOwned: ILlamaNFT[];
  tucosOwned: ILlamaNFT[];
}

const useValue = () => {
  const [llamaWallet, setLlamaWallet] = React.useState<ILlamaWallet>();

  return {
    llamaWallet,
    setLlamaWallet,
  };
};

export const LlamaWalletContext = React.createContext(
  {} as ReturnType<typeof useValue>
);

export const LlamaWalletContextProvider: React.FC<{}> = (props) => {
  return (
    <LlamaWalletContext.Provider value={useValue()}>
      {props.children}
    </LlamaWalletContext.Provider>
  );
};
