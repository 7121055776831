export default [
    {
        title: "War Paint",
        image_loc: "WarPaint.png",
        rarity: "0.84%"
    },
    {
        title: "Stringy",
        image_loc: "Stringy.png",
        rarity: "3.68%"
    },
    {
        title: "Curls",
        image_loc: "Curls.png",
        rarity: "4.05%"
    },
    {
        title: "Black Stripes",
        image_loc: "StripesBlack.png",
        rarity: "5.73%"
    },
    {
        title: "Black Streaks",
        image_loc: "StreaksBlack.png",
        rarity: "14.93%"
    },
    {
        title: "Large Black Spots",
        image_loc: "SpotsLargeBlack.png",
        rarity: "10.14%"
    },
    {
        title: "White Socks",
        image_loc: "SocksWhite.png",
        rarity: "10.90%"
    },
    {
        title: "Black Socks",
        image_loc: "SocksBlack.png",
        rarity: "11.80%"
    },
    {
        title: "Black Spots",
        image_loc: "SpotsBlack.png",
        rarity: "37.93%"
    },


]