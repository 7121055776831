import React, { useState, useEffect, useRef, useContext } from "react";
import { API_URL } from "../../config";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import BuyButton from "../buy_btn/BuyButton";
import { LlamaWalletContext } from "../wallet/WalletContext";
import { useParams } from "react-router-dom";
import ConnectBtn from "../connect_btn/ConnectBtn";
import LlamaProgressBar from "../llama_progress/LlamaProgress";
import LlamaRemaining from "../llama_progress/LlamaRemaining";
import { Link, NavLink } from "react-router-dom";

import "./Home.css";

function Home() {
  const myRef = useRef<HTMLInputElement>(null);

  const executeScroll = () => myRef?.current?.scrollIntoView();
  const { llamaWallet, setLlamaWallet } = useContext(LlamaWalletContext);

  let { section } = useParams<{ section: string }>();

  if (section != undefined) {
    executeScroll();
  }

  return (
    <div className="home">
      <Row className="hero">
        <Container>
          <Col>
            <h1>Meet Sollamas!</h1>
            <Col md={{ span: 6, offset: 3 }}>
              <p className="text-centre">
                8,888 uniquely generated, cute and collectible llamas with proof
                of ownership stored on the Solana blockchain.
              </p>
              {/* "https://solanart.io/collections/sollamas-gen2" */}
              <a href="https://solanart.io/collections/sollamas-gen2">
                <Button>Buy a Llama</Button>
              </a>
              <Link to="/my-sollamas">
                <Button className="btn-secondary">My Sollamas</Button>
              </Link>
            </Col>
          </Col>
        </Container>
      </Row>
      <div className="slider">
        <div
          className="slider-row"
          style={{
            backgroundImage: 'url("/llamariver.png")',
          }}
        ></div>
      </div>
      <Container>
        <Row className="padded-row-top" ref={myRef}>
          <Col className="center">
            <h2>Mint a Sollama</h2>
            <p className="pack-copy">
              As social, pack animals, llamas prefer to live in groups. Purchase
              from Sollama Singles, Packs and Herds. Mint a Sollama by
              connecting your Phantom or Sollet wallet.
            </p>
          </Col>
        </Row>

        <Row className="packs justify-content-center">
          <Col className="center col-md-4 col-12">
            <Image className="card-img" src="/cardsingle.png" fluid />
            <h5>The Single</h5>
            <h4>1x Sollama NFT</h4>
            <hr></hr>
            <h4>
              4 SOL <Image src="/sollogo.svg" fluid />
            </h4>
            <hr></hr>
            <p>= 4 SOL per NFT</p>
          </Col>

          <Col className="center col-md-4 col-12">
            <Image className="card-img" src="/cardpack.png" fluid />
            <h5>The Pack</h5>
            <h4>3x Sollama NFT's</h4>
            <hr></hr>
            <h4>
              11.7 SOL <Image src="/sollogo.svg" fluid />
            </h4>
            <hr></hr>
            <p>= 3.9 SOL per NFT</p>
          </Col>
        </Row>

        <Row className="cta-remaining-row">
          <Col>
            <LlamaRemaining />
          </Col>
        </Row>

        <Row className="padded-row">
          <Col>
            <hr></hr>
          </Col>
        </Row>

        <Row className="padded-row-bottom">
          <Col className="my-auto col-md-6 col-12 order-md-0 order-12">
            <h2 className="text-left">Not just a pretty face</h2>
            <p className="text-left">
              Each Sollama is as special as the next, but some Sollamas are
              rarer than the others. Sollamas come in different rarities ranging
              from the ultra-rare 'Artifact' to the common 'Unusual' Sollama.
              The rarer the attributes, the rarer your Sollama will be!
            </p>
          </Col>
          <Col className="col-md-6 col-12">
            <Image src="/prettyface.png" fluid />
          </Col>
        </Row>

        <Row className="padded-row-bottom">
          <Col className="my-auto col-md-6 col-12 order-md-0 order-0">
            <Image src="/attributes.png" fluid />
          </Col>
          <Col className="my-auto col-md-6 col-12">
            <h2 className="text-left">
              With over 165 unique attributes your Sollama will be truly one of
              a kind
            </h2>
            <p className="text-left">
              No two Sollamas will be the same. Each Sollama is generated with
              attributes based on rarity. From hats, glasses, necklaces, back
              blankets, beards to even the odd poop.
            </p>
          </Col>
        </Row>
        <Row className="padded-row-bottom">
          <Col className="my-auto col-md-6 col-12 order-md-0 order-12">
            <h2 className="text-left">
              Try your luck for an incredibly rare Alien, Zombie or Robot
              Sollama
            </h2>
            <p className="text-left">
              Each Sollama minted has the chance of being an ultra rare Alien,
              robot or zombie. How lucky will you be?
            </p>
          </Col>
          <Col className="col-md-6 col-12">
            <Image src="/rareboiz.png" fluid />
          </Col>
        </Row>
        <Row className="padded-row-bottom">
          <Col className="my-auto col-md-6 col-12 order-md-0 order-0">
            <Image src="/sollanallama.png" fluid />
          </Col>
          <Col className="my-auto col-md-6 col-12">
            <h2 className="text-left">
              Your Sollama is built and stored on Solana blockchain with
              ownership
            </h2>
            <p className="text-left">
              All Sollamas live on the Solana blockchain. This allows collectors
              to buy, sell and trade Sollamas with collectors from around the
              world.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
