import React, { useState, useContext } from "react";
import {
  Connection,
  SystemProgram,
  Transaction,
  PublicKey,
  Signer,
  Keypair,
} from "@solana/web3.js";

import EventEmitter from "eventemitter3";
// @ts-ignore
import Wallet from "@project-serum/sol-wallet-adapter";
import { PhantomWalletAdapter } from "./phantom";
import { CLUSTER_URL } from "../config";
import {
  TOKEN_PROGRAM_ID,
  SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
} from "./ids";
import { getMetadata, decodeMetadata } from "./metadata";
import { ILlamaNFT, ILlamaAttr } from "../components/wallet/WalletContext";
import { sha256, sha224 } from "js-sha256";
import { Token } from "@solana/spl-token";

const tucoDestKey = new PublicKey(
  "TuCoVdpvVsS25wRzcxsJyEvCKYNm1G2dS54KRLtsFgp"
);

export interface WalletAdapter extends EventEmitter {
  publicKey: PublicKey | null;
  signTransaction: (transaction: Transaction) => Promise<Transaction>;
  connected: boolean;
  connect: () => any;
  disconnect: () => any;
}

const ASSETS_URL =
  "https://raw.githubusercontent.com/solana-labs/oyster/main/assets/wallets/";
export const WALLET_PROVIDERS = [
  {
    name: "Phantom",
    url: "https://www.phantom.app",
    icon: `https://www.phantom.app/img/logo.png`,
    adapter: PhantomWalletAdapter,
  },
  {
    name: "Sollet",
    url: "https://www.sollet.io",
    con: `${ASSETS_URL}sollet.svg`,
  },
];

export let wallet: WalletAdapter;
const connection = new Connection(CLUSTER_URL, "confirmed");

export async function initWallet(provider_num: number): Promise<WalletAdapter> {
  const provider = WALLET_PROVIDERS[provider_num];
  wallet = new (provider.adapter || Wallet)(
    provider.url,
    CLUSTER_URL
  ) as WalletAdapter;
  await wallet.connect();
  console.log("wallet connected", wallet?.publicKey?.toBase58());
  return wallet;
}

export async function sendSol(destPubKeyStr: string, sol: number) {
  let destPubKey = new PublicKey(destPubKeyStr);

  // TODO create error popup if insufficient money
  let transaction = new Transaction().add(
    SystemProgram.transfer({
      fromPubkey: wallet!.publicKey!,
      toPubkey: destPubKey,
      lamports: sol * 1000 * 1000 * 1000,
    })
  );

  console.log("Getting recent blockhash");
  transaction.recentBlockhash = (
    await connection.getRecentBlockhash("max")
  ).blockhash;
  transaction.feePayer = wallet!.publicKey!;
  console.log("Sending signature request to wallet");
  let signed = await wallet.signTransaction(transaction);
  console.log("Got signature, submitting transaction");
  let signature = await connection.sendRawTransaction(signed.serialize());
  console.log("Submitted transaction " + signature + ", awaiting confirmation");

  let result = await connection.confirmTransaction(signature, "singleGossip");

  console.log("transaction confirmed", result);

  // if (!wallet.connected) {
  //   await wallet.disconnect();
  // }
}

export async function transferTuco(tuco: string, tucotoken: string) {
  const walletKey = wallet!.publicKey!;
  const signer: Signer = Keypair.generate();
  const tucoKey = new PublicKey(tuco);
  const tucoToken = new Token(connection, tucoKey, TOKEN_PROGRAM_ID, signer);
  let transaction = new Transaction();
  const associatedAddress = await Token.getAssociatedTokenAddress(
    SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
    TOKEN_PROGRAM_ID,
    tucoKey,
    tucoDestKey
  );
  try {
    await tucoToken.getAccountInfo(associatedAddress);
  } catch (err: any) {
    const FAILED_TO_FIND_ACCOUNT = "Failed to find account";
    const INVALID_ACCOUNT_OWNER = "Invalid account owner";
    if (
      err.message === FAILED_TO_FIND_ACCOUNT ||
      err.message === INVALID_ACCOUNT_OWNER
    ) {
      transaction.add(
        Token.createAssociatedTokenAccountInstruction(
          SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
          TOKEN_PROGRAM_ID,
          tucoKey,
          associatedAddress,
          tucoDestKey,
          walletKey
        )
      );
    }
  }
  transaction.add(
    Token.createTransferInstruction(
      TOKEN_PROGRAM_ID,
      new PublicKey(tucotoken),
      associatedAddress,
      walletKey,
      [],
      1
    )
  );

  transaction.recentBlockhash = (
    await connection.getRecentBlockhash("singleGossip")
  ).blockhash;

  transaction.feePayer = walletKey;

  const signed = await wallet.signTransaction(transaction);
  const signature = await connection.sendRawTransaction(signed.serialize(), {
    skipPreflight: true,
    preflightCommitment: "singleGossip",
  });
  console.log(
    "Submitted tuco transfer" + signature + ", awaiting confirmation"
  );

  const result = await connection.confirmTransaction(signature, "max");

  console.log("tuco transfer confirmed", result);
  return signature;
}

export async function setAllMints(walletAddr: string): Promise<ILlamaNFT[]> {
  const walletKey = new PublicKey(walletAddr);

  let mints: ILlamaNFT[] = [];

  const res = await connection.getParsedTokenAccountsByOwner(walletKey, {
    programId: TOKEN_PROGRAM_ID,
  });

  if (res.value.length > 0) {
    await Promise.all(
      res.value.map(async (element, index) => {
        let tokenAddr = element.pubkey.toBase58();
        let mint = element.account.data.parsed.info.mint;
        let tokenAmount = element.account.data.parsed.info.tokenAmount.amount;
        if (tokenAmount == 1) {
          let metadataAddr = await getMetadata(new PublicKey(mint));
          let accountInfo = await connection.getAccountInfo(
            new PublicKey(metadataAddr)
          );
          if (accountInfo) {
            let metadata = await decodeMetadata(accountInfo!.data);

            let creators: string[] = [];
            metadata.data?.creators?.forEach((element) => {
              creators.push(element.address.toString());
            });

            // Cracked Graves
            if (
              creators.includes(
                "TuCoVdpvVsS25wRzcxsJyEvCKYNm1G2dS54KRLtsFgp"
              ) &&
              creators.includes("LLAmArGWBCspEarLTCBpKLdXxYS4EUuiQZQmy1RD8oc")
            ) {
              let metaUri = metadata["data"]["uri"];
              let response = await fetch(metaUri);
              let data = await response.json();

              let mintN = parseInt(data["name"].split("Sollamas #")[1]);
              var _attrs = data["attributes"];
              var _name = "";
              var isDed = false;
              var order = 3;
              var hash = "";

              if (_attrs != undefined) {
                _attrs.forEach((element: any) => {
                  if (element["trait_type"] == "Name") {
                    _name = element["value"];
                  }

                  if (element["trait_type"] == "Cause of death") {
                    isDed = true;
                  }
                });
              }

              let _mint: ILlamaNFT = {
                mintId: mint,
                tokenAddr: tokenAddr,
                mintNum: mintN,
                generation: "crackedGraves",
                awImage: data["image"],
                description: data["description"],
                name: _name,
                isDed: isDed,
                order: order,
                hash: hash,
                attributes: [],
              };
              mints.push(_mint);
            }
            // Gen1
            else if (
              creators.includes("LLAmArGWBCspEarLTCBpKLdXxYS4EUuiQZQmy1RD8oc")
            ) {
              let metaUri = metadata["data"]["uri"];
              let response = await fetch(metaUri);
              let data = await response.json();

              let mintN = parseInt(data["name"].split("Sollamas #")[1]);
              var _attrs = data["attributes"];
              var _name = "";
              var isDed = false;
              var order = 3;
              var hash = "";

              if (_attrs != undefined) {
                _attrs.forEach((element: any) => {
                  if (element["trait_type"] == "Name") {
                    _name = element["value"];
                  }

                  if (element["trait_type"] == "Cause of death") {
                    isDed = true;
                  }
                });
              }

              let _mint: ILlamaNFT = {
                mintId: mint,
                tokenAddr: tokenAddr,
                mintNum: mintN,
                generation: "graves",
                awImage: data["image"],
                description: data["description"],
                name: _name,
                isDed: isDed,
                order: order,
                hash: hash,
                attributes: [],
              };
              mints.push(_mint);
            }
            // Gen 2
            else if (
              creators.includes("LamapQPXuMYEuvsyZqK2UPqn1XCT2sW1soURj7ZJkZF")
            ) {
              let metaUri = metadata["data"]["uri"];
              let response = await fetch(metaUri);
              let data = await response.json();

              let mintN = -1;
              var _attrs = data["attributes"];
              var _name = "";
              var isDed = false;
              let hash = "";
              let gender = "";
              var _attrs = data["attributes"];
              let cleanAttrs: ILlamaAttr[] = [];
              let generation: string = "base";
              let set = "";

              _attrs?.forEach((element: any) => {
                if (element["trait_type"] == "Sequence") {
                  if (typeof element["value"] !== "number") {
                    set = "airdrop";
                  }
                }
                if (element["trait_type"] == "Resurrected As") {
                  if (element["value"] == "Undead") {
                    generation = "undead";
                  }
                }
              });

              _attrs?.forEach((element: any) => {
                let a: ILlamaAttr = {
                  traitType: element["trait_type"],
                  value: String(element["value"]),
                };
                cleanAttrs.push(a);

                if (element["trait_type"] == "Name") {
                  _name = element["value"];
                }

                if (
                  element["display_type"] == "number" &&
                  element["trait_type"] == "sequence"
                ) {
                  mintN = element["value"];
                }
                if (element["trait_type"] == "Sequence") {
                  if (typeof element["value"] !== "number") {
                    mintN = parseInt(element["value"].replace("S", ""));
                  } else {
                    mintN = element["value"];
                  }
                }

                if (element["trait_type"] == "Gender") {
                  gender = element["value"];
                }
              });

              if (set == "airdrop") {
                if (
                  data["description"].split("blockchain. ")[1].split(" ")[0] ==
                  "She"
                ) {
                  gender = "Female";
                } else {
                  gender = "Male";
                }
              }
              if (data["description"].includes("airdrop")) {
                hash = sha256(`jonoisbirb${mintN} ${gender}`);
              } else {
                hash = sha256(`jlpbzfpm${mintN} ${gender}`);
              }
              let _mint: ILlamaNFT = {
                mintId: mint,
                tokenAddr: tokenAddr,
                mintNum: mintN,
                generation: generation,
                awImage: data["image"],
                description: data["description"],
                name: _name,
                isDed: isDed,
                order: 1,
                hash: hash,
                attributes: cleanAttrs,
              };
              mints.push(_mint);
            } else if (
              creators.includes(
                "pitpdb9WGNffLkHrfVmp5Lkfp6ZwXAZgM96vtVaQU2h"
              ) &&
              creators.includes("viprChBNoNG6EmEte3QgXhq2dWWJQ8X955CTgD5hidm")
            ) {
              let metaUri = metadata["data"]["uri"];
              let response = await fetch(metaUri);
              let data = await response.json();

              let mintN = parseInt(data["name"].split("Sollamas #")[1]);
              var _attrs = data["attributes"];
              var _name = "";
              var isDed = false;
              var order = 3;
              var hash = "";

              mintN = -99;
              _name = "Tuco, the UGLY";
              order = 2;

              _attrs?.forEach((element: any) => {
                if (element["trait_type"] == "Name") {
                  _name = element["value"];
                }

                if (element["trait_type"] == "Cause of death") {
                  isDed = true;
                }
              });

              let _mint: ILlamaNFT = {
                mintId: mint,
                tokenAddr: tokenAddr,
                mintNum: mintN,
                generation: "tuco",
                awImage: data["image"],
                description: data["description"],
                name: _name,
                isDed: isDed,
                order: order,
                hash: hash,
                attributes: [],
              };
              mints.push(_mint);
            }
          }
        }
      })
    );
  }

  mints.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0));
  return mints;
}

export async function disconnectWallet() {
  await wallet.disconnect();
}

// TuCoVdpvVsS25wRzcxsJyEvCKYNm1G2dS54KRLtsFgp", "LLAmArGWBCspEarLTCBpKLdXxYS4EUuiQZQmy1RD8oc
