import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';



import "./Attributes.css";

import sollama_type from "./types_data"
import sollama_bg from "./bg_data"
import sollamas_tail from "./tail_data"
import sollamas_pattern from "./pattern_data"

import rarity_data from "./rarity_data.json"


function Attributes() {
  return (
    <Tab.Container id="uncontrolled-tab-example" defaultActiveKey="first">
      <div className="attributes">
        <Row className="hero">
          <Container>
            <Row>
              <Col>
                <h1 className="text-left">Breeds, attributes and backdrops</h1>
                <p className="text-left">Over 165 unique attributes to style your Sollamas.</p>
              </Col>
            </Row>
          </Container>
          <Container>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="first">Llamas</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second" disabled>Undead</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third" disabled>Ghosts</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="fourth" disabled>Solana Edition</Nav.Link>
              </Nav.Item>
            </Nav>
          </Container>
        </Row>

        <Container>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <Row className="text-left attr-kind-row rarity-dist">
                <Col>
                  <h3>Rarity Distribution</h3>
                  <hr></hr>
                  <ProgressBar>
                    <ProgressBar className="rarity-artifact" striped variant="success" now={1} key={1} />
                    <ProgressBar className="rarity-legendary" striped now={5} key={2} />
                    <ProgressBar className="rarity-epic" striped now={12} key={3} />
                    <ProgressBar className="rarity-rare" striped now={22} key={4} />
                    <ProgressBar className="rarity-uncommon" striped now={60} key={5} />

                  </ProgressBar>

                  <Row className="text-center">
                    <Col md={{ span: 2, offset: 1 }}>
                      <Row>
                        <Col className="col-md-12 col-4 rarity-tag">
                          <Image src='/artifact-tag.svg' />
                        </Col>
                        <Col className="col-md-12 col-4">
                          <h4>100</h4>
                        </Col>
                        <Col className="col-md-12 col-4 rarity-pct">
                          <p>1.00%</p>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={{ span: 2 }}>
                      <Row>
                        <Col className="col-md-12 col-4 rarity-tag">
                          <Image src='/legendary-tag.svg' />
                        </Col>
                        <Col className="col-md-12 col-4">
                          <h4>500</h4>
                        </Col>
                        <Col className="col-md-12 col-4 rarity-pct">
                          <p>5.00%</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={{ span: 2 }}>
                      <Row>
                        <Col className="col-md-12 col-4 rarity-tag">
                          <Image src='/epic-tag.svg' />
                        </Col>
                        <Col className="col-md-12 col-4">
                          <h4>1200</h4>
                        </Col>
                        <Col className="col-md-12 col-4 rarity-pct">
                          <p>12.00%</p>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={{ span: 2 }}>
                      <Row>
                        <Col className="col-md-12 col-4 rarity-tag">
                          <Image src='/rare-tag.svg' />
                        </Col>
                        <Col className="col-md-12 col-4">
                          <h4>2200</h4>
                        </Col>
                        <Col className="col-md-12 col-4 rarity-pct">
                          <p>22.00%</p>
                        </Col>
                      </Row>
                    </Col>

                    <Col md={{ span: 2 }}>
                      <Row>
                        <Col className="col-md-12 col-4 rarity-tag">
                          <Image src='/unusual-tag.svg' />
                        </Col>
                        <Col className="col-md-12 col-4">
                          <h4>6000</h4>
                        </Col>
                        <Col className="col-md-12 col-4 rarity-pct">
                          <p>60.00%</p>
                        </Col>
                      </Row>
                    </Col>



                  </Row>

                </Col>

              </Row>
              <Row className="text-left attr-kind-row">
                <Col>
                  <h3>Breeds</h3>
                  <hr></hr>
                  <Row>
                    {
                      sollama_type.map(sollama_type => {
                        return (
                          <Col className="col-lg-4 col-md-6 col-12">
                            <Row>
                              <Col className="col-md-4"><Image src={'/attributes/colour/' + sollama_type.image_loc} fluid /></Col>
                              <Col className="col-md-6 attr-text">
                                <h4>{sollama_type.title}</h4>
                                <p>{sollama_type.rarity}</p>
                              </Col>
                            </Row>
                          </Col>
                        )
                      })}
                  </Row>
                </Col>
              </Row>
              <Row className="text-left attr-kind-row">
                <Col>
                  <h3>Patterns</h3>
                  <hr></hr>
                  <Row>
                    {
                      sollamas_pattern.map(sollama_pat => {
                        return (
                          <Col className="col-lg-4 col-md-6 col-12">
                            <Row>
                              <Col className="col-md-4"><Image src={'/attributes/colour/' + sollama_pat.image_loc} fluid /></Col>
                              <Col className="col-md-6 attr-text">
                                <h4>{sollama_pat.title}</h4>
                                <p>{sollama_pat.rarity}</p>
                              </Col>
                            </Row>
                          </Col>
                        )
                      })}
                  </Row>
                </Col>
              </Row>
              <Row className="text-left attr-kind-row">
                <Col>
                  <h3>Backdrops</h3>
                  <hr></hr>
                  <Row>
                    {
                      sollama_bg.map(sollama_bg => {
                        return (
                          <Col className="col-lg-4 col-md-6 col-12">
                            <Row>
                              <Col className="col-md-4"><Image src={'/attributes/colour/' + sollama_bg.image_loc} fluid /></Col>
                              <Col className="col-md-6 attr-text">
                                <h4>{sollama_bg.title}</h4>
                                <p>{sollama_bg.rarity}</p>
                              </Col>
                            </Row>
                          </Col>
                        )
                      })}
                  </Row>
                </Col>
              </Row>
              {
                rarity_data.map(rarity_data => {
                  return (
                    <Row className="text-left attr-kind-row last-attr">
                      <Col>
                        <h3>{rarity_data.attr_title}</h3>
                        <hr></hr>
                        <Row>

                          {
                            rarity_data.variants.map(r =>

                              <Col className="col-lg-4 col-md-6 col-12">
                                <Row>
                                  <Col className="col-md-4"><Image src={'/attributes/colour/' + r.image_loc} fluid /></Col>
                                  <Col className="col-md-6 attr-text">
                                    <h4>{r.title}</h4>
                                    <p>{r.rarity}</p>
                                  </Col>
                                </Row>
                              </Col>
                            )
                          }
                        </Row>

                      </Col>
                    </Row>

                  )
                })
              }
              <Row className="text-left attr-kind-row">
                <Col>
                  <h3>Tails</h3>
                  <hr></hr>
                  <Row>
                    {
                      sollamas_tail.map(tail => {
                        return (
                          <Col className="col-lg-4 col-md-6 col-12">
                            <Row>
                              <Col className="col-md-4"><Image src={'/attributes/colour/' + tail.image_loc} fluid /></Col>
                              <Col className="col-md-6 attr-text">
                                <h4>{tail.title}</h4>
                                <p>{tail.rarity}</p>
                              </Col>
                            </Row>
                          </Col>
                        )
                      })}
                  </Row>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              2
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              3
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
              4
            </Tab.Pane>


          </Tab.Content>
        </Container>

      </div >
    </Tab.Container>
  );
}

export default Attributes;