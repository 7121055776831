export default [
  {
    n: 0,
    question: "What is Sollamas NFT?",
    answer:
      "Sollamas are 8,888 algorithmically generated, unique, cute and collectible Llamas with proof of ownership stored on the Solana blockchain!",
  },

  {
    n: 1,
    question: "How do I purchase a Sollama?",
    answer:
      "Purchasing a Sollama is super simple. Connect your Solana wallet and follow the buy prompts. \n1. Download the Phantom or Sollet wallet extension for your browser(Google Chrome recommended). \n2. Buy Solana(SOL) on an exchange like FTX, Binance, Coinbase etc and send it to your Phantom or Sollet wallet Public Address. \n3. Connect your Wallet to our website by clicking on the Connect button. \n4. Click the “Buy” button on https://www.sollamas.com and approve the transaction in your Wallet.",
  },
  {
    n: 2,
    question: "Where does my NFT go after I purchase a Sollama?",
    answer:
      "Your Sollama NFT artwork will be sent to the wallet you purchased via in a matter of minutes.",
  },
  {
    n: 3,
    question: "What art do I receive as the NFT?",
    answer:
      "You can download your Sollama NFT artwork in the My Sollamas page. In addition to your 576*576 NFT artwork, you will also find profile picture cutouts and the Rarity collectible cards (display purposes only... for now) in your https://www.sollamas.com/ MySollamas page.",
  },

  {
    n: 4,
    question: "What artwork can I download?",
    answer:
      "You can download your Sollama NFT artwork in the My Sollamas page. In addition to your 576*576px NFT artwork, you will also find profile picture cutouts and the Rarity collectible cards (display purposes only) in your https://www.sollamas.com MySollamas page.",
  },

  {
    n: 5,
    question: "What Solana wallets can I use?",
    answer:
      "At launch we will be allowing purchases and management through two of Solana’s main wallets; Phantom and Sollet.",
  },

  {
    n: 6,
    question: "What can I do with my Sollama NFT?",
    answer:
      "You are free to do anything with your Sollamas under a non-exclusive license.",
  },

  {
    n: 7,
    question: "How were the Sollamas generated?",
    answer:
      "All individual pieces of the Sollama world have been created by hand and algorithmically generated using custom code. There are 165 attributes across 18 categories: These categories include background, body, type, pattern, tail, expression, mouth, eyes, skin, glasses, facial hair, hat/hair, mouth accessories, earring, neck, blankets, flags and best of all, poop. \n With these combined, it gives a total mathematical combination of millions of unique Sollamas - but with only 10,000 being minted, some are more unique and rare than others.",
  },
  {
    n: 8,
    question: "Who is behind Sollamas NFT?",
    answer:
      "The Sollamas NFT team are a small group of friends with the same vision and goal. Our core principles have always been to deliver the best possible NFT user experience from launch as well as creating collectible art with utility.",
  },
  {
    n: 9,
    question: "Llama-nomics?",
    answer:
      "We have reserved 100 Sollamas for giveaways, competitions and owner airdrops. Most of these will be given out after launch and will not occupy the early token ids. ",
  },
  {
    n: 10,
    question:
      "Are you affiliated with any other projects on the Solana network?",
    answer:
      "No, we are not affiliated with any other projects on Solana or in the crypto space. We are an independent team with one focus - Sollamas NFT.",
  },
  {
    n: 11,
    question: "Will there be resale royalties?",
    answer:
      "5% royalties will be applied to all Llama resales. We will be using these funds for future development.",
  },
  {
    n: 12,
    question: "Get in touch with us",
    answer:
      "Feel free to get in touch with us on Twitter @sollamasNFT or on discord https://www.discord.gg/sollamasnft",
  },
];
